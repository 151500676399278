import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { NavItem } from '../shared/models';
import Faq from './pages/faq/Faq';
import FaqEdit from './pages/faq/FaqEdit';
import News from './pages/news/News';
import NewsEdit from './pages/news/NewsEdit';
import Parts from './pages/parts/Parts';
import PartEdit from './pages/parts/PartEdit';
import Login from './pages/Login';

interface Props {
  onSignIn: (username: string, password: string) => void;
  onSignOut: () => void;
}

export default class AppRoutes {
  private static props: Props;

  public static setup(props: Props) {
    this.props = props;
  }

  public static getRoutes(signedIn: boolean, signInErrorMessage: string) {
    return [
      {
        exact: true,
        path: '/',
        component: (
          <Redirect to="/login"/>
        )
      },
      {
        component: News,
        exact: true,
        icon: 'list layout',
        label: "News",
        leftNav: true,
        path: "/news",
        private: true,
        routes: [
          {
            component: NewsEdit,
            leftNav: false,
            path: "/news/:id",
            private: true
          }
        ]
      },
      {
        component: Parts,
        exact: true,
        icon: 'list layout',
        label: "Parts",
        leftNav: true,
        path: "/parts",
        private: true,
        routes: [
          {
            component: PartEdit,
            leftNav: false,
            path: "/parts/:id",
            private: true
          }
        ]
      },
      {
        component: Faq,
        exact: true,
        icon: 'list layout',
        label: "FAQs",
        leftNav: true,
        path: "/faq",
        private: true,
        routes: [
          {
            component: FaqEdit,
            leftNav: false,
            path: "/faq/:id",
            private: true
          }
        ]
      },
      {
        component: (
          <Login
            errorMessage={signInErrorMessage}
            onSignIn={this.props.onSignIn}
            signedIn={signedIn}
          />
        ),
        icon: 'user',
        isLogin: true,
        label: 'Login',
        leftNav: true,
        path: '/login'
      },
      {
        icon: 'home',
        label: 'Public Site',
        leftNav: true,
        onClick: () => open(process.env.REACT_APP_PUBLIC_URL),
        private: true
      },
      {
        icon: 'user',
        label: 'Logout',
        leftNav: true,
        onClick: this.props.onSignOut,
        path: '/logout',
        private: true
      },
      {
        component: signedIn ? (
          <div>
            <h3>
              No match for <code>{location.pathname}</code>
            </h3>
          </div>
        ) : <Redirect to='/'/>
      }
    ] as NavItem[];
  }
}