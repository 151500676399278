import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Icon, Table } from 'semantic-ui-react';

import { NewsModel } from '../../../shared/models';
import NewsController from '../../../controllers/NewsController';

export default class News extends React.Component {
  state = {
    newsItems: [] as NewsModel[],
    path: ''
  };

  constructor(props: any) {
    super(props);
    
    this.state = {
      newsItems: [],
      path: props.match.path
    };

    this.loadData();
  }

  render() {
    const { newsItems } = this.state;
    return (
      <div>
        <Header as="h1">News</Header>
        {this.renderAddButton()}
        <Header as="h3">
          {newsItems ? newsItems.length : 'no'} news
        </Header>
        {this.renderNewsTable()}
      </div>
    );
  }

  private loadData = () => {
    NewsController.list().then((newsItems: NewsModel[]) => {
      this.setState({newsItems});
    });
  }

  private renderAddButton(): JSX.Element {
    return (
      <NavLink
        className="ui button primary"
        style={{'textAlign': 'left'}}
        to={`${this.state.path}/add`}
      >
        <Icon name="plus" /> Add
      </NavLink>
    );
  }

  private renderNewsTable = () => {
    const { newsItems, path } = this.state;
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Published Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {newsItems.map(news => {
            return (
              <Table.Row key={news._id}>
                <Table.Cell>
                  <NavLink
                    to={`${path}/${news._id}`}
                  >
                    <Icon name="pencil" /> {news.Title}
                  </NavLink>
                </Table.Cell>
                <Table.Cell>{news.PublishedDate.format('MM/DD/YYYY hh:mm a')}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    );
  }

};