import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Header, Input, Message } from 'semantic-ui-react';

interface Props {
  errorMessage?: string;
  onSignIn: (username: string, password: string) => void;
  signedIn: boolean;
}

export default class UserLogin extends React.Component<Props, {}> {
  state = {
    username: '' as string,
    password: '' as string,
  };

  constructor(props: Props) {
    super(props);
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    this.setState({
      // tslint:disable-next-line:no-any
      [target.name as any]: target.value,
    });
  }

  signIn = () => {
    this.props.onSignIn(this.state.username, this.state.password);
  }

  render() {
    if (this.props.signedIn) {
      return <Redirect to="/news" />;
    }
    return (
      <Form>
        <Header as="h1">Login</Header>
        {this.renderUsername()}
        {this.renderPassword()}
        {this.props.errorMessage === '' ? '' : this.renderError()}
        <Button color="red" fluid={true} onClick={this.signIn}>
          Log In
        </Button>
      </Form>
    );
  }

  private renderUsername(): JSX.Element {
    return this.renderInput(
      'Email', 
      'username', 
      this.state.username, 
      'text'
    );
  }

  private renderPassword(): JSX.Element {
    return this.renderInput(
      'Password', 
      'password', 
      this.state.password, 
      'password'
    );
  }

  private renderInput(label: string, name: string, value: string, type?: string, placeholder?: string): JSX.Element {
    const inputType = type === undefined ? 'text' : type;
    const inputPH = placeholder === undefined ? '' : placeholder;
    return (
      <Form.Field>
        <label>{label}</label>
        <Input
          fluid={true}
          type={inputType}
          name={name}
          value={value}
          onChange={this.handleChange}
          placeholder={inputPH}
        />
      </Form.Field>
    );
  }

  private renderError(): JSX.Element {
    return (
      <Message
        compact={true}
        content={this.props.errorMessage}
        color="red"
        style={{ textAlign: 'center' }}
        visible={true}
      />
    );
  }

}
