import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Header, Icon, Table } from 'semantic-ui-react';

import { FaqModel } from '../../../shared/models';
import FaqController from '../../../controllers/FaqController';

export default class Faq extends React.Component {
  state = {
    items: [] as FaqModel[],
    path: ''
  };

  constructor(props: any) {
    super(props);
    
    this.state = {
      items: [],
      path: props.match.path
    };

    this.loadData();
  }

  render() {
    const { items } = this.state;
    return (
      <div>
        <Header as="h1">FAQs</Header>
        {this.renderAddButton()}
        <Header as="h3">
          {items ? items.length : 'no'} items
        </Header>
        {this.renderTable()}
      </div>
    );
  }

  private loadData = () => {
    FaqController.list().then((items: FaqModel[]) => {
      this.setState({items});
    });
  }

  private renderAddButton(): JSX.Element {
    return (
      <NavLink
        className="ui button primary"
        style={{'textAlign': 'left'}}
        to={`${this.state.path}/add`}
      >
        <Icon name="plus" /> Add
      </NavLink>
    );
  }

  private renderTable = () => {
    const { items, path } = this.state;
    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item, i) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <NavLink
                    to={`${path}/${item.id}`}
                  >
                    <Icon name="pencil" /> {item.SortOrder}. {item.Title}
                  </NavLink>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    );
  }

};