import * as moment from 'moment';
import { SemanticICONS } from 'semantic-ui-react';

export class EmailSettings {
  enabled: boolean;
  log_enabled: boolean;
  override_enabled: boolean;
}

export class FaqModel {
  id: string = '';
  Title: string;
  Content: string;
  SortOrder: number;
}

export class NavItem {
  component?: any;
  exact?: boolean;
  icon?: SemanticICONS;
  isLogin?: boolean;
  label: string;
  leftNav?: boolean;
  onClick?: () => void;
  path: string;
  private?: boolean;
  routes?: NavItem[];
}

export class NewsModel {
  _id: string = '';
  Title: string;
  MainContent: string;
  PublishedDate: moment.Moment;
  SummaryContent: string;
}

export class NewsletterModel {
  _id: string = '';
  Body?: string;
  Subject: string;
  CreatedDate: moment.Moment;
  Stats: NewsletterStatsModel[];
}

export class NewsletterStatsModel {
  NewsletterSendId: number;
  RowCount: number;
  SendCount: number;
  Type: number;
  StartDate: moment.Moment;
  EndDate: moment.Moment;
}

export class NewsletterSubscriberModel {
  EmailAddress: string;
}

export class PartModel {
  id: string = '';
  CreatedDate: moment.Moment;
  MainImage: string;
  MainImageUrl?: string;
  MinQuantity: number;
  PartNumber: string;
  PartTitle: string;
  Price: number;
}