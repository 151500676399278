import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Divider, Grid, Header, Icon, Image, Label } from 'semantic-ui-react';

import { PartModel } from '../../../shared/models';
import PartController from '../../../controllers/PartController';

export default class Part extends React.Component {
  state = {
    items: [] as PartModel[],
    path: ''
  };

  constructor(props: any) {
    super(props);
    
    this.state = {
      items: [],
      path: props.match.path
    };

    this.loadData();
  }

  render() {
    return (
      <Container>
        <Header as="h1">Parts</Header>

        <Header as="h3">
          {this.renderAddButton()}
        </Header>

        {/* <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3">
                {items ? items.length : 'no'} parts
              </Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              {this.renderAddButton()}
            </Grid.Column>
          </Grid.Row>
        </Grid> */}

        <Divider/>

        <Container>
          {/* {this.renderTable()} */}
          {this.renderGrid()}
        </Container>
      </Container>
    );
  }

  private loadData = () => {
    PartController.list().then((items: PartModel[]) => {
      this.setState({items});
    });
  }

  private renderAddButton(): JSX.Element {
    return (
      <NavLink
        className="ui button primary"
        style={{'textAlign': 'left'}}
        to={`${this.state.path}/add`}
      >
        <Icon name="plus" /> New Part
      </NavLink>
    );
  }

  private renderGrid() {
    const { items, path } = this.state;
    return (
      <Grid>
        {items.map((item, i) => (
          <Grid.Column key={i} width={4}>
            <div style={{height: '82px', textAlign: 'center'}}>
              {item.MainImage ? 
                <a href={item.MainImageUrl} target="_blank">
                  <Image
                    centered
                    src={item.MainImageUrl}
                    size='tiny'
                  />
                </a>
                : <Icon color="grey" name="file image" size="huge" />
              }
            </div>
            <Container textAlign="center">
              <NavLink
                to={`${path}/${item.id}`}
              >
                <Label>
                  {item.PartNumber} {item.PartTitle}
                </Label>
                <br/>
                <Label basic>$ {item.Price.toFixed(2)}</Label>
              </NavLink>
            </Container>
          </Grid.Column>
        ))}
      </Grid>
    );
  }

  // private renderTable() {
  //   const { items, path } = this.state;
  //   return (
  //     <Table padded striped>
  //       <Table.Header>
  //         <Table.Row>
  //           <Table.HeaderCell></Table.HeaderCell>
  //           <Table.HeaderCell>Part</Table.HeaderCell>
  //         </Table.Row>
  //       </Table.Header>
  //       <Table.Body>
  //         {items.map((item, i) => {
  //           return (
  //             <Table.Row key={item.id}>
  //               <Table.Cell collapsing>
  //                 <NavLink
  //                   to={`${path}/${item.id}`}
  //                 >
  //                   <Icon name="pencil" /> {item.PartNumber}
  //                 </NavLink>
                  
  //                 <div style={{marginTop: '8px', width: '85px', height: '85px'}}>
  //                   <Image
  //                     centered
  //                     src={item.MainImage} 
  //                     size='tiny'
  //                   />
  //                 </div>
                  
  //               </Table.Cell>
  //               <Table.Cell verticalAlign='top'>
  //                 <Label content={item.PartTitle} />
  //               </Table.Cell>
  //             </Table.Row>
  //           )
  //         })}
  //       </Table.Body>
  //     </Table>
  //   );
  // }

};