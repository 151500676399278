import * as moment from 'moment';
import * as React from 'react';
import ReactCKEditor from 'react-ckeditor5-classic';
import { Checkbox, CheckboxProps, Dropdown, Grid, Input, SemanticWIDTHS, TextArea } from 'semantic-ui-react';

interface BaseProps {
  label: string;
  value: boolean | number | string | moment.Moment | '';
  labelCols?: SemanticWIDTHS;
  ctrlCols?: SemanticWIDTHS;
  vertical?: boolean;
}

interface Props extends BaseProps {
  name: string;
  onChange: (name: string, value: any) => void;
}

type CTRL_TYPES = 'checkbox' | 'currency' | 'date' | 'datetime' | 'dropdown' | 'richtext' | 'static' | 'text' | 'textarea' | 'time';

export default class GridCtrl extends React.Component<Props> {
  private static readonly LABEL_COLS = 4 as SemanticWIDTHS;
  private static readonly CTRL_COLS = 12 as SemanticWIDTHS;

  public static Checkbox = (props: Props) => GridCtrl.RenderRow(props, 'checkbox')
  public static Date = (props: Props) => GridCtrl.RenderRow(props, 'date')
  public static DateTime = (props: Props) => GridCtrl.RenderRow(props, 'datetime')
  public static Dropdown = (props: Props, selectOptions: {}[]) => GridCtrl.RenderRow(props, 'dropdown', selectOptions)
  public static Number = (props: Props) => GridCtrl.RenderRow(props, 'currency')
  public static RichText = (props: Props) => GridCtrl.RenderRow(props, 'richtext')
  public static Static = (props: BaseProps) => GridCtrl.RenderRow(props as Props, 'static')
  public static Text = (props: Props) => GridCtrl.RenderRow(props, 'text')
  public static Textarea = (props: Props) => GridCtrl.RenderRow(props, 'textarea')
  public static Time = (props: Props) => GridCtrl.RenderRow(props, 'time')

  private static RenderControl(
    props: Props,
    type: CTRL_TYPES = 'text',
    selectOptions?: {}[] | null
  ): JSX.Element {
    switch (type) {
      case 'checkbox':
        return (
          <Checkbox 
            checked={props.value as boolean}
            // label={name}
            onChange={(ev, data: CheckboxProps) => props.onChange(props.name, data.checked)}
            style={{display: 'block', paddingBottom: '5px'}}
          />
        );
      case 'currency':
      case 'date':
      case 'time':
      case 'text':
        return (
          <Input
            fluid={true}
            type={type === 'currency' ? 'number' : type}
            name={props.name}
            value={props.value}
            onChange={(ev) => props.onChange(props.name, ev.currentTarget.value)}
          />
        );
      case 'datetime':
        const dt = moment(props.value as string).format('YYYY-MM-DD');
        const tm = moment(props.value as string).format('HH:mm');
        return (
          <div>
            <Input
              type='date'
              name={props.name}
              value={dt}
              onChange={(ev, data) => props.onChange(props.name, data.value + ' ' + tm)}
            />
            &nbsp;&nbsp;&nbsp;
            <Input
              type='time'
              name={props.name}
              value={tm}
              onChange={(ev, data) => props.onChange(props.name, dt + ' ' + data.value)}
            />
          </div>
        );
      case 'dropdown':
        return (
          <Dropdown 
            value={props.value as string}
            onChange={(ev, data) => props.onChange(props.name, data.value)}
            // onChange={(ev, data: { value: string }) => this.handleChange(props.name, data, props.onChange)}
            options={selectOptions || []}
            selection={true} 
          />
        );
      case 'richtext':
        return (
          <ReactCKEditor
            name={props.name}
            content={props.value}
            onChange={(content: string) => props.onChange(props.name, content)}
          />
        );
      case 'static':
        return <div dangerouslySetInnerHTML={{ __html: props.value as string }}/>;
      case 'textarea':
        return (
          <div className="ui form">
            <TextArea
              name={props.name}
              value={props.value as string}
              onChange={(ev) => props.onChange(props.name, ev.currentTarget.value)}
            />
          </div>
        );
    }
  }

  private static RenderRow(
    props: Props,
    type: CTRL_TYPES = 'text',
    selectOptions?: {}[] | null
  ): JSX.Element {
    if (props.vertical) {
      return (
        <Grid.Row>
          <Grid.Column>
            <label>{props.label}</label>
            {this.RenderControl(props, type, selectOptions)}
          </Grid.Column>
        </Grid.Row>
      );
    } else {
      return (
        <Grid.Row>
          <Grid.Column width={props.labelCols || this.LABEL_COLS}>
            <label>{props.label}</label>
          </Grid.Column>
          <Grid.Column width={props.ctrlCols || this.CTRL_COLS}>
            {this.RenderControl(props, type, selectOptions)}
          </Grid.Column>
        </Grid.Row>
      );
    }
  }
}