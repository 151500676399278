import * as React from 'react';
import { Button, Grid, Icon, Image, Input, Segment } from 'semantic-ui-react';

import { PartModel } from '../../../shared/models';
import PartController from '../../../controllers/PartController';
import GridCtrl from '../../components/GridCtrl';

export default class PartEdit extends React.Component {
  promptMsg = 'There may be unsaved changes, are you sure you would like to leave the page?';
  state = {
    id: '',
    hasChanges: false as boolean,
    item: new PartModel() as PartModel,
    loaded: false as boolean,
    previewImage: null as null | File,
  };

  constructor(props: any) {
    super(props);
    
    this.state.id = props.match.params.id;
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <h1>{this.state.id === 'add' ? 'Add' : 'Edit'} Part</h1>
        {!this.state.loaded || this.renderForm()}
      </div>
    );
  }

  private handleDeleteClick = () => {
    if (confirm('Are you sure you want to delete this part?')) {
      PartController.delete(this.state.item.id).then(() => history.back());
    }
  }

  private handleDoneClick = (isCancel: boolean = false) => {
    const hasChanges = this.state.hasChanges;
    if (!isCancel) {
      const { item } = this.state;
      if (item && this.isFormValid()) {
        // update
        PartController.update(item).then((x: string) => history.back());
      }
    } else {
      if (hasChanges && confirm(this.promptMsg) || !hasChanges) {
        history.back();
      }
    }
  }

  private handleChange = (name: string, value: any) => {
    let { item } = this.state;
    if (item) {
      item[name] = value;
      this.setState({item, hasChanges: true});
    }
  }

  private handleUpdateImage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let previewImage = this.state.previewImage;

    const curFiles = ev.target.files;
    if (curFiles && curFiles.length > 0) {
      previewImage = curFiles[0];
    } else {
      previewImage = null;
    }

    this.setState({previewImage});
  }

  private handleUploadImage = () => {
    if (this.state.previewImage) {
      const formData = new FormData();

      // image
      formData.append( 
        "partImage", 
        this.state.previewImage, 
        this.state.previewImage.name 
      );

      PartController.image_upload(formData).then((res) => {
        if (res['success'] === true) {
          let item = this.state.item;
          item.MainImage = res['fname'];
          item.MainImageUrl = res['furl'];
          this.setState({item, previewImage: null});
        }
      });
    }
  }

  private isFormValid() {
    const { item } = this.state;
    if (item) {
      if (item.PartNumber === undefined) {
        alert('Part number must be entered.');
        return false;
      }
      if (item.PartTitle === undefined) {
        alert('Part name must be entered.');
        return false;
      }
      if (item.MinQuantity === undefined) {
        alert('Quantity must be entered.');
        return false;
      }
      if (item.Price === undefined) {
        alert('Price must be entered.');
        return false;
      }
    }
    return true;
  }

  private loadData = () => {
    if (this.state.id && this.state.id !== 'add') {
      PartController.get(this.state.id).then(res => {
        this.setState({ item: res, loaded: true });
      });
    }
    else {
      this.setState({ loaded: true });
    }
  }

  private renderForm = () => {
    const { item } = this.state;

    return (
      <div>
        <Segment>
          <Grid>
            <GridCtrl.Text label="Part Number" name="PartNumber" value={item.PartNumber} onChange={this.handleChange}/>
            <GridCtrl.Text label="Part Name" name="PartTitle" value={item.PartTitle} onChange={this.handleChange}/>
            <GridCtrl.Number 
              label="Quantity" 
              name="MinQuantity" 
              value={item.MinQuantity} 
              labelCols={4}
              ctrlCols={2}
              onChange={this.handleChange}
            />
            <GridCtrl.Number 
              label="Price $" 
              name="Price" 
              value={item.Price} 
              labelCols={4}
              ctrlCols={3}
              onChange={this.handleChange}
            />
            {this.renderGridCtrlImage()}
          </Grid>
        </Segment>
        {this.renderFormButtons()}
      </div>
    );
  }

  private renderGridCtrlImage() {
    const { item, previewImage } = this.state;

    let divPreview;
    if (previewImage) {
      const url = URL.createObjectURL(previewImage);
      divPreview = (
        <p>
          <a href={url} target="_blank">
            <img className="ui tiny image" src={url} />
          </a>
          <Button content="Upload" onClick={this.handleUploadImage} />
        </p>
      );
    } else {
      divPreview = <p>No file currently selected for upload</p>;
    }

    return (
      <Grid.Row>
        <Grid.Column width={4}>
          <label>Part Image</label>
        </Grid.Column>
        <Grid.Column width={6}>
          {item.MainImage &&
            <a href={item.MainImageUrl} target="_blank">
              <Image
                src={item.MainImageUrl}
                size='tiny'
              />
            </a> 
          }
          <Button primary style={{margin: '2px 0px'}}>
            <label htmlFor="MainImage">
              <i className='search icon' />
              Choose image to upload
            </label>
          </Button>
          <Input
            accept="image/*"
            id="MainImage"
            type="file"
            multiple
            name="MainImage"
            onChange={(ev) => this.handleUpdateImage(ev)}
            style={{display:'none'}}
          />
          {divPreview}
        </Grid.Column>
      </Grid.Row>
    );
  }

  private renderFormButtons = () => {
    return (
      <div style={{marginTop: '15px'}}>
        <Button 
          positive={true} 
          icon={true}
          onClick={() => this.handleDoneClick()}
        >
          <Icon name="save" /> Save
        </Button>
        <Button 
          color="red" 
          icon={true}
          onClick={() => this.handleDoneClick(true)}
        >
          Cancel <Icon name="remove" />
        </Button>
        {this.state.id && this.state.id !== 'add' &&
          <Button 
            color="red"
            floated="right" 
            icon={true}
            onClick={() => this.handleDeleteClick()}
          >
            Delete <Icon name="trash" />
          </Button>
        }
      </div>
    );
  }

};