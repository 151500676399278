import * as React from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';

import { FaqModel } from '../../../shared/models';
import FaqController from '../../../controllers/FaqController';
import GridCtrl from '../../components/GridCtrl';

export default class FaqEdit extends React.Component {
  promptMsg = 'There may be unsaved changes, are you sure you would like to leave the page?';
  state = {
    id: '',
    hasChanges: false as boolean,
    item: new FaqModel() as FaqModel,
    loaded: false as boolean
  };

  constructor(props: any) {
    super(props);
    
    this.state.id = props.match.params.id;
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <h1>Edit FAQ</h1>
        {!this.state.loaded || this.renderForm()}
      </div>
    );
  }

  private handleDoneClick = (isCancel: boolean = false) => {
    const hasChanges = this.state.hasChanges;
    if (!isCancel) {
      const { item } = this.state;
      if (item && this.isFormValid()) {
        // update news
        FaqController.update(item).then((x: string) => {
          this.setState({ hasChanges: false }, () => {
            history.back();
          });
        });
      }
    } else {
      if (hasChanges && confirm(this.promptMsg) || !hasChanges) {
        this.setState({ hasChanges: false }, () => {
          history.back();
        });
      }
    }
  }

  private handleChange = (name: string, value: any) => {
    let { item } = this.state;
    if (item) {
      item[name] = value;
      this.setState({item, hasChanges: true});
    }
  }

  private isFormValid() {
    const { item } = this.state;
    if (item) {
      if (item.Title === undefined) {
        alert('Title must be entered.');
        return false;
      }
      if (item.Content === undefined) {
        alert('Content must be entered.');
        return false;
      }
    }
    return true;
  }

  private loadData = () => {
    if (this.state.id && this.state.id !== 'add') {
      FaqController.get(this.state.id).then(res => {
        this.setState({ item: res, loaded: true });
      });
    }
    else {
      this.setState({ loaded: true });
    }
  }  

  private renderForm = () => {
    return (
      <div>
        {this.renderFormSegment(this.state.item)}
        {this.renderFormButtons()}
      </div>
    );
  }

  private renderFormButtons = () => {
    return (
      <div style={{marginTop: '15px'}}>
        <Button 
          positive={true} 
          icon={true}
          onClick={() => this.handleDoneClick()}
        >
          <Icon name="save" /> Save
        </Button>
        <Button 
          color="red" 
          icon={true}
          onClick={() => this.handleDoneClick(true)}
        >
          Cancel <Icon name="remove" />
        </Button>
      </div>
    );
  }

  private renderFormSegment = (item: FaqModel) => {
    return (
      <Segment>
        <Grid>
          <GridCtrl.Text label="Title" name="Title" value={item.Title} onChange={this.handleChange}/>
          <GridCtrl.RichText label="Content" name="Content" value={item.Content} onChange={this.handleChange}/>
        </Grid>
      </Segment>
    );
  }

};