import * as moment from 'moment';
import * as React from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';

import { NewsModel } from '../../../shared/models';
import NewsController from '../../../controllers/NewsController';
import GridCtrl from '../../components/GridCtrl';

export default class NewsEdit extends React.Component {
  promptMsg = 'There may be unsaved changes, are you sure you would like to leave the page?';
  state = {
    id: '',
    hasChanges: false as boolean,
    item: new NewsModel() as NewsModel,
    loaded: false as boolean
  };

  constructor(props: any) {
    super(props);
    
    this.state.id = props.match.params.id;
    this.state.item.PublishedDate = moment();
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <h1>News Edit</h1>
        {!this.state.loaded || this.renderForm()}
      </div>
    );
  }

  private handleDoneClick = (isCancel: boolean = false) => {
    const hasChanges = this.state.hasChanges;
    if (!isCancel) {
      const { item } = this.state;
      if (item && this.isFormValid()) {
        // update news
        NewsController.update(item).then((x: string) => {
          this.setState({ hasChanges: false }, () => {
            history.back();
          });
        });
      }
    } else {
      if (hasChanges && confirm(this.promptMsg) || !hasChanges) {
        this.setState({ hasChanges: false }, () => {
          history.back();
        });
      }
    }
  }

  private handleChange = (name: string, value: any) => {
    let { item } = this.state;
    if (item) {
      item[name] = value;
      this.setState({item, hasChanges: true});
    }
  }

  private isFormValid() {
    const { item } = this.state;
    if (item) {
      if (item.Title === undefined) {
        alert('Title must be entered.');
        return false;
      }
      if (item.PublishedDate === undefined) {
        alert('Published Date must be entered.');
        return false;
      }
      if (item.MainContent === undefined) {
        alert('Main Content must be entered.');
        return false;
      }
      if (item.SummaryContent === undefined) {
        alert('Summary Content must be entered.');
        return false;
      }
    }
    return true;
  }

  private loadData = () => {
    if (this.state.id && this.state.id !== 'add') {
      NewsController.get(this.state.id).then(res => {
        this.setState({ item: res, loaded: true });
      });
    }
    else {
      this.setState({ loaded: true });
    }
  }  

  private renderForm = () => {
    return (
      <div>
        {this.renderFormSegment(this.state.item)}
        {this.renderFormButtons()}
      </div>
    );
  }

  private renderFormButtons = () => {
    return (
      <div style={{marginTop: '15px'}}>
        <Button 
          positive={true} 
          icon={true}
          onClick={() => this.handleDoneClick()}
        >
          <Icon name="save" /> Save
        </Button>
        <Button 
          color="red" 
          icon={true}
          onClick={() => this.handleDoneClick(true)}
        >
          Cancel <Icon name="remove" />
        </Button>
      </div>
    );
  }

  private renderFormSegment = (item: NewsModel) => {
    return (
      <Segment>
        <Grid>
          <GridCtrl.Text label="Title" name="Title" value={item.Title} onChange={this.handleChange}/>
          <GridCtrl.DateTime label='Published Date' name='PublishedDate' value={item.PublishedDate} onChange={this.handleChange}/>
          <GridCtrl.RichText label="Main Content" name="MainContent" value={item.MainContent} onChange={this.handleChange}/>
          <GridCtrl.RichText label="Summary Content" name="SummaryContent" value={item.SummaryContent} onChange={this.handleChange}/>
        </Grid>
      </Segment>
    );
  }

};