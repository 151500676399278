import Api from '../shared/api';
import Http from '../shared/http';

export default class UserController extends Api {

  public static login(username: string, password: string): Promise<{} | undefined> {
    const data = {
      username: username,
      password: password
    };
    return Http.post('user/login', data).then((result: {} | undefined) => {
      return result === undefined ? Promise.resolve({}) : Promise.resolve(result);
    });
  }
  
}
