import * as moment from 'moment';
import * as React from 'react';
import { Redirect, BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import { Dimmer, Loader, Sidebar, Segment, Menu, Icon, Header } from 'semantic-ui-react';

import UserController from '../controllers/UserController';

import Api from '../shared/api';
import Http from '../shared/http';
import { NavItem } from '../shared/models';
import AppRoutes from './AppRoutes';
import './App.css';

const pj = require('../../package.json');

export default class App extends React.Component {
  state = {
    id: null as string | null,
    loading: false as boolean,
    signedIn: false as boolean,
    signInErrorMessage: '' as string
  };

  constructor(props: {}) {
    super(props);

    // init state
    this.state.id = localStorage.getItem('id');
    this.state.signedIn = localStorage.getItem('signedIn') === 'true';

    // setup api
    Http.setup(Api.BaseUrl, this.handleShowLoader, this.handleHideLoader, this.handleHideLoader);

    // setup routes
    AppRoutes.setup({ 
      onSignIn: this.handleSignIn,
      onSignOut: this.handleSignOut
    });
  }

  render() {
    // Routes
    const routes = AppRoutes.getRoutes(this.state.signedIn, this.state.signInErrorMessage);

    return (
      <Router>
        <Sidebar.Pushable as={Segment}>
          <Dimmer active={this.state.loading}>
            <Loader content='Loading' />
          </Dimmer>
          {this.renderMenuLinks(routes)}
          {this.renderRoutes(routes)}
        </Sidebar.Pushable>
      </Router>
    );
  }

  private handleHideLoader = () => {
    this.setState({loading: false});
  }

  private handleShowLoader = () => {
    this.setState({loading: true});
  }

  private handleSignIn = (username: string, password: string) => {
    UserController.login(username, password).then(res => {
      if (res && res['id']) {
        localStorage.setItem('id', res['id']);
        localStorage.setItem('signedIn', 'true');
        this.setState({ 
          id: res['id'],
          signedIn: true,
          signInErrorMessage: '' 
        });
      }
      else {
        localStorage.clear();
        this.setState({ 
          signedIn: false,
          signInErrorMessage: 'Invalid Login' 
        });
      }
    });
  }

  private handleSignOut = () => {
    localStorage.clear();
    this.setState({
      id: null,
      signedIn: false
    });
  }

  private renderMenuLink(route: NavItem) {
    if (route.isLogin && this.state.signedIn) {
      return '';
    }
    else if (route.private && !this.state.signedIn) {
      return '';
    }
    else if (route.leftNav) {
      return (
        <Menu.Item key={moment().format()} style={{'textAlign': 'left'}}>
          <NavLink
            isActive={(match, location) => location.pathname.startsWith(route.path)}
            exact={true}
            to={route.path || ''}
            onClick={route.onClick}
            activeClassName="ui button primary"
            style={{'width': '100%', 'textAlign': 'left'}}
          >
            {route.icon ? <Icon name={route.icon}/> : ''} {route.label}
          </NavLink>
        </Menu.Item>
      );
    }

    return '';
  }

  private renderMenuLinks(routes: NavItem[]) {
    return (
      <Sidebar as={Menu} width="thin" visible={true} icon="labeled" vertical={true} inverted={true}>
        <Menu.Item name="brand" key="/">
          <Header as="h3" inverted={true}>hoB Admin</Header>
          {pj.version}
        </Menu.Item>
        {routes.map((route) => {
          return [
            this.renderMenuLink(route),
            route.routes ? route.routes.map((routeChild) => this.renderMenuLink(routeChild)) : ''
          ];
        })}
      </Sidebar>
    );
  }

  private renderRoute = (route: NavItem) => {
    // check if route is protected and if user is authenticated
    let canView = route.private && this.state.signedIn || !route.private;

    return route.component ? (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        render={props => {
          if (canView) {
            if (typeof(route.component) === 'function') {
              return <route.component {...props} />;
            }
            else {
              return route.component;
            }
          }
          return <Redirect to="/"/>;
        }}
      />
    ) : '';
  }

  private renderRoutes(routes: NavItem[]) {
    return (
      <Sidebar.Pusher>
        <Segment basic={true}>
          <Switch>
            {routes.map((route) => {
              return [
                this.renderRoute(route),
                route.routes ? route.routes.map((routeChild) => this.renderRoute(routeChild)) : ''
              ];
            })}
          </Switch>
        </Segment>
      </Sidebar.Pusher>
    );
  }
}