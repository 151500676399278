import Api from '../shared/api';
import Http from '../shared/http';

import { PartModel } from '../shared/models';

export default class PartController extends Api {

  public static delete(id: string) {
    return Http.delete('part/delete', { id: id }).then((result: any) => {
      return Promise.resolve(result);
    });
  }

  public static get(id: string): Promise<PartModel | undefined> {
    return Http.get('part/' + id).then((result: PartModel | undefined) => {
      if (result === undefined) {
        return Promise.resolve(undefined);
      }
      else {
        return Promise.resolve(result);
      }
    });
  }

  public static list(): Promise<PartModel[]> {
    return Http.get('part/list').then((result: PartModel[]) => {
      if (result === undefined) {
        return Promise.resolve([]);
      }
      else {
        // result = result.sort((n1,n2) => {
        //   if (n1.PartNumber > n2.PartNumber) {
        //     return 1;
        //   }
        //   if (n1.PartNumber < n2.PartNumber) {
        //     return -1;
        //   }
        //   return 0;
        // });
        return Promise.resolve(result);
      }
    });
  }

  public static image_upload(item: FormData) {
    return Http.upload('part/image/upload', item).then((result: string) => {
      return Promise.resolve(result);
    });
  }
  
  public static update(item: PartModel) {
    return Http.post('part/update', item).then((result: string) => {
      return Promise.resolve(result);
    });
  }

}